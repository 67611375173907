import { xs } from './utils/breakpoints'

/**
 * Remove animation delay for .no-delay-xs classes
 */
document.addEventListener("DOMContentLoaded", () => {
  if (! xs.matches) {
    return;
  }

  document.querySelectorAll('.elementor-element.elementor-invisible.no-delay-xs').forEach(el => {
    try {
      const settings = JSON.parse(el.dataset.settings)
      delete settings._animation_delay
      Object.assign(el.dataset, { settings: JSON.stringify(settings) })
    } catch(e) {
      console.log(e)
    }
  })
})
