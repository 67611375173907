import "../styles/main.scss";
import "../images/autoload/*";

import "../images/footer/logo.svg";
import "../images/footer/cc.svg";
import "../images/footer/dss.svg";
import "../images/footer/finanstisinet.svg";
import "../images/footer/ssl.svg";
import "../images/hero-shape.svg";

import Swal from "sweetalert2";
import getMobileOperatingSystem from "./utils/mobile-os-detect";

import "./sticky-header";
import "./animations";

const $window = $(window);

document.addEventListener("DOMContentLoaded", () => {
  const appLinks = document.querySelectorAll(".app-link"),
    mobileOS = getMobileOperatingSystem(),
    androidAppLink =
      "https://play.google.com/store/apps/details?id=eu.settle.app",
    iOSAppLink = "https://itunes.apple.com/no/app/settle/id1440051902?mt=8";

  const osHrefs = {
    iOS: iOSAppLink,
    Android: androidAppLink,
  };

  appLinks.forEach((appLink) => {
    const anchor =
      appLink.tagName.toLowerCase() === "a"
        ? appLink
        : appLink.querySelector("a");

    const isAnchor = !!anchor;

    if (osHrefs[mobileOS]) {
      if (isAnchor) {
        const href =
          mobileOS === "iOS"
            ? osHrefs[mobileOS].replace(/^https?/, "itms-apps")
            : osHrefs[mobileOS];
        anchor.setAttribute("href", href);
        anchor.setAttribute("target", "_blank");
        anchor.setAttribute("rel", "noreferrer noopener");
      } else {
        appLink.addEventListener("click", (e) => {
          e.preventDefault();

          window.open(osHrefs[mobileOS]);
        });
      }
    } else {
      appLink.addEventListener("click", (e) => {
        e.preventDefault();

        Swal.fire({
          html: document.querySelector("#get-app-popup-contents").innerHTML,
          customClass: {
            container: "swal2-app-popup",
          },
          showConfirmButton: false,
          showCloseButton: true,
        });
      });
    }
  });
});

document.addEventListener("DOMContentLoaded", () => {
  document
    .querySelector(".overlay-menu-toggle")
    .addEventListener("click", (e) => {
      const toggler = e.currentTarget,
        bodyClassList = document.body.classList;

      // .focus just removed by theme
      if (!toggler.classList.contains("focus")) {
        bodyClassList.add("overlay-menu-open");
        // .focus just added by theme
      } else {
        bodyClassList.remove("overlay-menu-open");
      }
    });
});

$window.on("elementor/frontend/init", function () {
  
  /**
   * Dispatch events of parent skins which we extend
   * so Elementor loads the scripts for async paging, etc.
   */
  elementorFrontend.hooks.addAction('frontend/element_ready/posts.settle-cards', (...args) => {
    elementorFrontend.hooks.doAction('frontend/element_ready/posts.cards', ...args)
  })
  
  elementorFrontend.hooks.addAction('frontend/element_ready/archive-posts.settle_archive_cards', (...args) => {
    elementorFrontend.hooks.doAction('frontend/element_ready/archive-posts.archive_cards', ...args)
  })
  
  /**
   * Enable swipeToSlide slick option in horizontal-timeline widget
   */
  elementorFrontend.hooks.addAction(
    "frontend/element_ready/ha-horizontal-timeline.default",
    ($scope, $) => {
      $(".slick-slider", $scope).slick(
        "slickSetOption",
        {
          swipeToSlide: true,
        },
        true
      );
    },
    1000
  );
});
