import raf from "raf";

const CNAME_HEADER_SOLID_BG = "header-sticking";

document.addEventListener("DOMContentLoaded", () => {
  const header = document.querySelector("#Top_bar"),
    headerRect = header.getBoundingClientRect();

  const updateBodyClass = () =>
    raf(() => {
      if (window.scrollY >= headerRect.top) {
        if (!document.body.classList.contains(CNAME_HEADER_SOLID_BG)) {
          document.body.classList.add(CNAME_HEADER_SOLID_BG);
        }
      } else if (document.body.classList.contains(CNAME_HEADER_SOLID_BG)) {
        document.body.classList.remove(CNAME_HEADER_SOLID_BG);
      }
    });

  window.addEventListener("scroll", updateBodyClass);
  updateBodyClass();
});
